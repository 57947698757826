$(function(){
var new_item = "";
    $.ajax({
        url: '//shopping.geocities.jp/isshusouden/docs/json/newitems.jsonp',
        type:"GET",
        dataType:"jsonp",
		jsonpCallback: 'newitems',
        error:function() {
//            alert("ロード失敗");
        },
        success: function(json){
   		 	var len = json.length;
    		for(var i=0; i < len; i++){
	            new_item = '<li>' + '<a href="https://issyusouden.com/products/' + json[i].itemnum + '" target="_top">' + '<img src="' + json[i].img + '">' + '</a><br>' + '<span class="name">' + json[i].name1 + '</span></li>';
   				$('#Arrival ul').append(new_item);
   			}
		}
    })
});